import { createApp } from "vue";
import app from "./App.vue";
import router from "./router";
import store from "./store";
import 'sweetalert2/dist/sweetalert2.min.css';
import { STN } from "@/modules/init_utilities.js";
import { initFirebaseSdk } from "@/modules/init_firebase.js";
import { initFacebookSdk } from "@/modules/init_facebook.js";

// wait for firebase sdk 
initFirebaseSdk().then(() => {
    // wait for facebook sdk 
    initFacebookSdk().then(() => {
        const App = createApp(app);

        // global error handler
        App.config.errorHandler = function (err, vm, info) {
            // handle error
            // `info` is a Vue-specific error info, e.g. which lifecycle hook
            // the error was found in. Only available in 2.2.0+
            STN.logError("main.js", "errorHandler: \nerr:" + err + "\nvm:" + vm + "\ninfo:" + info);
            //alert( "errorHandler: \nerr:" + err + "\nvm:" + vm + "\ninfo:" + info);
        };

        // global error handler
        window.onerror = function (msg, url, line, col, error) {
            STN.logError("main.js", "window.onerror: \nmsg:" + msg + "\nurl:" + url + "\nline:" + line + "\ncol:" + col + "\nerror:" + error);
            //alert("window.onerror: \nmsg:" + msg + "\nurl:" + url + "\nline:" + line + "\ncol:" + col + "\nerror:" + error);
        };

        // global error handler
        window.addEventListener('unhandledrejection', function(event) {
            //event.promise contains the promise object
            //event.reason contains the reason for the rejection
            STN.logError("main.js", "unhandledrejection: \n" + event.reason);
            //alert("unhandledrejection: \n" + event.reason);
        });

        App
        .use(router)
        .use(store)
        .provide("STN", App.config.globalProperties.STN = STN)  // eliminates need for inject: ["STN"] in components
        .mount('#app');

        if ('serviceWorker' in navigator) {
            // Listen for messages from service worker
            navigator.serviceWorker.addEventListener('message', event => {
                if (event && event.data && event.data.type === 'navigate') {
                    window.location.href = event.data.url;
                    window.focus();
                }
            });
          } else {
            console.warn('Service workers are not supported in this browser.');
          }
    });
});

