import { STN } from "@/modules/init_utilities.js";
import router from "../router";
import swal from 'sweetalert2'
import store from "@/store/index";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import { getAuth, signOut } from "firebase/auth";

const initialState = { status: { loggedIn: false }, user: null };

export const auth = {
    inject: ['messaging'], // Use the inject option to access the provided messaging object
    namespaced: true,
    state: initialState,
    actions: {
        async login({ commit }, p_oCredentials) {
            try{
                if (!STN.isBlank(p_oCredentials)){
                    let getAutoLoginResult = async() => {
                        let l_oDataModel = {
                            EncryptedEvaluatorID: p_oCredentials.encryptedEvaluatorID,
                        };
            
                        return await STN.stnFetch(
                            "auth.module.js",
                            "getAutoLoginResult",
                            "POST",
                            "AutoLogin",
                            l_oDataModel,
                            true
                        );
                    };

                    let getAutoLoginResultBestmarkResult = async() => {
                        let l_oDataModel = {
                            EncryptedEvaluatorID: p_oCredentials.encryptedEvaluatorID,
                            EncryptedAssignmentID: p_oCredentials.encryptedAssignmentID
                        };
            
                        return await STN.stnFetch(
                            "auth.module.js",
                            "getAutoLoginResultBestmarkResult",
                            "POST",
                            "AutoLoginBestMark",
                            l_oDataModel,
                            true
                        );
                    };

                    let getLoginResult = async() => {
                        await this.commit('setAuthHeader', "Basic " + btoa(p_oCredentials.username + ":" + p_oCredentials.password));

                        return await STN.stnFetch(
                            "auth.module.js",
                            "getLoginResult",
                            "POST",
                            "Login",
                        );
                    };

                    let getOAuthLoginResult = async() => {
                        await this.commit('setAuthHeader', "OAuth " + btoa(p_oCredentials.type + ":" + p_oCredentials.authID));

                        return await STN.stnFetch(
                            "auth.module.js",
                            "getOAuthLoginResult",
                            "POST",
                            "LoginOAuth",
                        );
                    };

                    let l_oResult;

                    if (!STN.isBlank(p_oCredentials.encryptedEvaluatorID) && !STN.isBlank(p_oCredentials.encryptedAssignmentID) && !STN.isBlank(p_oCredentials.bn)) {
                        // auto login from web/email link, qs value "bn" is present signifying Bestmark NV shopper
                        l_oResult = await getAutoLoginResultBestmarkResult();
                    }
                    else if (!STN.isBlank(p_oCredentials.encryptedEvaluatorID)) {
                        // auto login from web/email link
                        l_oResult = await getAutoLoginResult();
                    }
                    else if (p_oCredentials.type == "Basic") {
                        // username / password login                 
                        l_oResult = await getLoginResult();
                    }
                    else {
                        // oauth login
                        l_oResult = await getOAuthLoginResult();
                    }

                    if (STN.isBlank(l_oResult)){
                        commit("logout");
                        return "There was a problem with your request, please try again";
                    }
                    else if (l_oResult && l_oResult.EvaluatorID && l_oResult.EvaluatorID > 0) {
                        if (l_oResult.Active == 1) {
                            //after successful login, authheader for each subsequent request must be in the format evaluatorid:token
                            await this.commit('setAuthHeader', "Basic " + btoa(l_oResult.EvaluatorID + ":" + l_oResult.Token));
                            
                            let l_oGetCommonPageDataResult = await STN.getCommonPageData(true);

                            await commit("loginSuccess", l_oGetCommonPageDataResult, btoa(l_oResult.EvaluatorID + ":" + l_oResult.Token + ":" + l_oGetCommonPageDataResult.ForumUsername));

                            if (l_oResult.ForceChange) {
                                router.replace({
                                    name: "myaccount_changepassword",
                                    params: {
                                        fc: 1
                                    }
                                });
                            } else {
                                if (p_oCredentials.remember) {
                                    localStorage.setItem("RememberMe",  JSON.stringify(p_oCredentials));
                                } else if (STN.isBlank(localStorage.getItem("RememberMe"))) {
                                    localStorage.setItem("RememberMe", null);
                                }

                                try {
                                    // setup notifications / FCM
                                    Notification.requestPermission().then(permission => {
                                        if (permission === 'granted') {
                                            // Permission granted, we can now use FCM getToken()
                                            const messaging = getMessaging();

                                            // use the PUBLIC key
                                            getToken(messaging, {
                                                vapidKey: "BCAIXX8Y-SPzvk1tLWeqrPfHMExWkCEtkZCXEezXPROU4vi1HDavWIGAejwKzbYla48g8ynRRBGbaGXSJXBZQN0"
                                            }).then(async (currentToken) => {
                                                if (currentToken) {
                                                    // saveFCMToken 
                                                    let l_oDataModel = {
                                                        Token: currentToken,
                                                        IsMobile: false
                                                    };
                                        
                                                    await STN.stnFetch(
                                                        "auth.module.js",
                                                        "saveFCMToken",
                                                        "POST",
                                                        "SaveFCMTokenNEW",
                                                        l_oDataModel
                                                    );
                                                                                              
                                                    onMessage(messaging, (payload) => {
                                                        //// console.log('onMessage Message received. ', payload);

                                                        swal.fire({
                                                            text: payload.data.title, //"We have found new open shops near you!  Would you like to see them?",
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#F26E17',
                                                            cancelButtonColor: '#008CEE',
                                                            confirmButtonText: 'YES',
                                                            cancelButtonText: 'NO',
                                                            allowOutsideClick: false
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                if (STN.isBlank(payload.data.params)) {
                                                                    STN.redirect("dashboard");
                                                                } else {
                                                                    var jsonObject = JSON.parse(payload.data.params);
                            
                                                                    STN.redirect(jsonObject);
                                                                }
                                                            } else {
                                                                STN.hideProgress();
                                                                swal.close();
                                                            }
                                                        });
                                                    });
                                                } 
                                            }).catch((err) => {
                                                // console.log('An error occurred while retrieving token. ', err);
                                            });
                                        } 
                                    });
                                } catch (e) {
                                    // nada
                                }
        
                                // resolved, make sure to pass through to next() if opening from external link / autoLogin
                                if (STN.isBlank(p_oCredentials.encryptedEvaluatorID)) {
                                    let _gaoh = store.getters.getGigspotAssignmentOnHold;
                                    if (!STN.isBlank(_gaoh)){

                                        // flag their login with this attribute
                                        await STN.setPersonAttribute("IS-GS", "YES");
                                        
                                        STN.redirect({
                                            name: "findshops_preview",
                                            params: {
                                                assignmentID: _gaoh,
                                                callingPage: "findshops_results_map",
                                                pageName: this.pageName,
                                            },
                                        });
                                    } else {
                                        // make sure to pass through to next() if opening from external link / autoLogin
                                        if (STN.isBlank(p_oCredentials.encryptedEvaluatorID)) {

                                            // was BackgroundMessagePayloadParams saved in router -> PreRouteChecks / not logged in???
                                            let l_oBackgroundMessagePayloadParams = store.getters.getBackgroundMessagePayloadParams;
                                            if (!STN.isBlank(l_oBackgroundMessagePayloadParams)) {
                                                // clear out from store
                                                store.commit('setBackgroundMessagePayloadParams', null);

                                                STN.redirect(l_oBackgroundMessagePayloadParams);
                                            } else {
                                                router.push("dashboard");

                                                // testing
                                                //router.push("myshops_attention");

                                                // // testing
                                                // router.push({
                                                //     name: "myshops_clarification?ID=5214699",
                                                //     params: {
                                                //         assignmentID: 5214699
                                                //     }
                                                // }); 
                                                
                                                // // testing
                                                // STN.redirect({
                                                //     name: "questionnaire",
                                                //     params: {
                                                //         assignmentID: 5214701,
                                                //         cQID: 5417772,
                                                //         qID: 1400,
                                                //         startQuestion: 6
                                                //     }
                                                // });
                                            }
                                        } 
                                    }                                   
                                } else if (!STN.isBlank(p_oCredentials.bn)) {
                                    // special case for bestmark nv manual login
                                    return l_oResult;
                                }
                            }
                        } else {
                            commit("logout");
                            return "Your account is inactive, contact the <a href='mailto:helpdesk@cxgroup.com?subject=Inactive%20Evaluator%20" + l_oResult.EvaluatorID + "' target='_new'>CX Group Helpdesk</a> for more information";
                        }
                    } else {
                        commit("logout");

                        if (p_oCredentials.type != "Basic") {
                            // OAuth and does not exist in system, go to apply
                            router.push({
                                name: "apply_continueapplication",
                            }); 
                        }
                        else {
                            return "*Invalid credentials";
                        }
                    }
                }
            } catch(e) {
                commit("logout");
                STN.logError("auth.module.js", "login:  " + e, false, false);
                return "There was a problem with your request, please try again";
            }
        },
        async logout({ commit }) {
            let getLogoutResult = async() => {
                let l_oDataModel = {
                    IsMobile: false,
                };
    
                return await STN.stnFetch(
                    "auth.module.js",
                    "getLogoutResult",
                    "POST",
                    "Logoff",
                    l_oDataModel,
                    true
                );
            };

            await getLogoutResult();

            commit("logout");
        },  
    },
    mutations: {
        loginSuccess(state, user, forumAuthHeader) {
            state.status.loggedIn = true;
            state.user = user;
            state.forumAuthHeader = forumAuthHeader;
        },
        logout(state) {
            const auth = getAuth();

            signOut(auth)
                .then(() => {
                    //this.user = null;
                    //console.log('User signed out');
                })
                .catch((error) => {
                    //console.error('Error signing out:', error);
                });
            
            state.status.loggedIn = false;
            state.shopSearchResults = [];
            state.shopsNearMeResults = [];
            state.authHeader = null;
            state.forumAuthHeader = null;
            state.user = null;
            localStorage.setItem("RememberMe", null);
        },
    },
};