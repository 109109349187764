import { createWebHashHistory, createRouter } from "vue-router";  // createWebHashHistory needed for cordova
import store from "@/store/index";
import { STN } from "@/modules/init_utilities.js";

// run before each route begins, to prevent and/or redirect where needed based on apply state, url values,
const PreRouteChecks = async function (to, from, next) {
    window.setTimeout(async () => {
        // open shop email
        // https://localhost:8085/#/findshops_results_map?eid=yw9PTvHckLU%3d&ProjectType=0
        // https://shopperhub-stage.cxgroup.com/#/findshops_results_map?eid=yw9PTvHckLU%3d&ProjectType=0

        // email verification
        // https://localhost:8085/#/myaccount_verifyemail?eid=yw9PTvHckLU%3d&ID=tLevnX9o%2fCZemLqoEqMXZb2yHzm2EqsYjkpsCI6hzSKobbAhqe4%2bWg%3d%3d

        // clarification
        // https://localhost:8085/#/myshops_clarification?ID=4131733&eid=yw9PTvHckLU%3d&uid=1
        // https://shopperhub.cxgroup.com/link_myshops_clarification.min.html?ID=4048550&eid=yw9PTvHckLU%3d&uid=1
        // https://shopperhub.cxgroup.com/link_myshops_clarification.min.html?ID=4048550&eid=yw9PTvHckLU%3d&uid=1
        // https://shopperhub.cxgroup.com/link_myshops_clarification.min.html?ID=5116142&eid=RNY8tkN1nk4%3d&uid=1
        // https://localhost:8085/#/myshops_clarification?ID=5116142&eid=RNY8tkN1nk4%3d&uid=1

        // bestmark nv manual login
        // https://localhost:8085/#/login?eid=RNY8tkN1nk4%3d&bn=lXpE%2foi%2bvfY%3d&aid=m4%2fW0m17Hbk%3d

        // other vendor login
        // https://localhost:8085/#/login?eid=o62b0o%2b%2bdvA%3d  (eid is 3967074)

        let encryptedEvaluatorID = to.query.eid;
        let encryptedAssignmentID = to.query.aid;
        let bn = to.query.bn;

        // perform autoLogin?
        if (!STN.isBlank(encryptedEvaluatorID)) {
            window.setTimeout(async () => {
                let l_oCredentials = {
                    type: "Basic",
                    encryptedEvaluatorID: encryptedEvaluatorID,
                    encryptedAssignmentID: encryptedAssignmentID,
                    bn: bn,
                    remember: "",
                }
    
                // login with vuex
                await store.dispatch("auth/login", l_oCredentials).then(
                    async (result) => {
                        if (await STN.isVendorLogin() == true) {
                            if (await FilterVendorLoginAccess(to) == true) {
                                // route allowed, proceed
                                STN.actionMessageClear();
                                next();
                            } else {
                                // route not allowed, deny / redirect
                                STN.redirect({
                                    name: "myshops_scheduled"
                                });
                            }
                        } else if (await STN.isBestmarkNevadaMultishopperLogin() == true) {
                            if (!STN.isBlank(result) && !STN.isBlank(result.AssignmentID)) {
                                // first site hit from link, redirect
                                STN.redirect({
                                    name: "findshops_preview",
                                    params: {
                                        assignmentID: result.AssignmentID,
                                        lastViewedShop: result.AssignmentID,
                                        callingPage: "dashboard",
                                        pageName: "findshops_preview",
                                    },
                                });
                            } else {
                                if (await FilterBestmarkNevadaMultishopperLoginAccess(to) == true) {
                                    // route allowed, proceed
                                    STN.actionMessageClear();
                                    next();
                                } else {
                                    // route not allowed, deny / redirect
                                    let error = "Please try accessing the site again from your email link.";
                                    STN.logError(
                                        "router.js",
                                        "PreRouteChecks - BestmarkNevadaMultishopperLogin Link Error1:  " + error
                                    );
                                }
                            }
                        } else {
                            let newParams = to.query;
                            delete newParams["eid"];

                            //alert(to.name + " :: " + JSON.stringify(newParams));

                            if (to.name == "login") {
                                STN.redirect({
                                    name: "dashboard",
                                    params: newParams
                                });
                            } else {
                                STN.redirect({
                                    name: to.name,
                                    params: newParams
                                });
                            } 
                        }
                    },
                    (error) => {
                        STN.logError(
                            "router.js",
                            "PreRouteChecks - Error1:  " + error
                        );
                        router.replace("/login");
                    }
                );
            }, 1000);
        }
        // normal navigation
        else {
            if (await store.state.auth.status.loggedIn) {
                if (await STN.isVendorLogin() == true) {
                    if (await FilterVendorLoginAccess(to) == true) {
                        // route allowed, proceed
                        STN.actionMessageClear();
                        next();
                    } else {
                        // route not allowed, deny / redirect
                        STN.redirect({
                            name: "myshops_scheduled"
                        });
                    }
                } else if (await STN.isBestmarkNevadaMultishopperLogin() == true) {
                    if (await FilterBestmarkNevadaMultishopperLoginAccess(to) == true) {
                        // route allowed, proceed
                        STN.actionMessageClear();
                        next();
                    } else {
                        // route not allowed, deny / redirect
                        let error = "Please try accessing the site again from your email link.";
                        STN.logError(
                            "router.js",
                            "PreRouteChecks - BestmarkNevadaMultishopperLogin Link Error2:  " + error
                        );
                    }
                } else {
                    let l_oInfo = await store.getters.getUser;

                    if (l_oInfo.EmailAddress.indexOf("privaterelay.appleid.com") != -1 
                        && to.name != "helpdesk" 
                        && to.name != "myaccount_requirenewemail")  // emails contains "privaterelay.appleid.com"
                    {
                        router.replace("myaccount_requirenewemail");
                    }
                    else if (l_oInfo.ForceChange 
                        && to.name != "helpdesk" 
                        && to.name != "myaccount_requirenewemail"
                        && to.name != "myaccount_changepassword") {
                        router.replace({
                            name: "myaccount_changepassword",
                            params: {
                                fc: 1
                            }
                        });
                    }
                    else if (l_oInfo.HasUnsignedICA 
                        && to.name != "helpdesk" 
                        && to.name != "myaccount_requirenewemail"
                        && to.name != "myaccount_changepassword"
                        && to.name != "myaccount_ica_sign") {
                        router.replace("myaccount_ica_sign");
                    }
                    else if (l_oInfo.HasUnansweredRequiredDemographics 
                        && to.name != "helpdesk" 
                        && to.name != "myaccount_requirenewemail"
                        && to.name != "myaccount_changepassword"
                        && to.name != "myaccount_ica_sign"
                        && to.name != "myaccount_extended_required") {
                        router.replace("myaccount_extended_required");
                    }
                    else if (l_oInfo.HasExpiredDemographics 
                        && to.name != "helpdesk" 
                        && to.name != "myaccount_requirenewemail"
                        && to.name != "myaccount_changepassword"
                        && to.name != "myaccount_ica_sign"
                        && to.name != "myaccount_extended_required"
                        && to.name != "myaccount_extended_refresh") {
                        router.replace("myaccount_extended_refresh");
                    }
                    else {
                        // user logged in, proceed
                        STN.actionMessageClear();
                        next();
                    }
                }
            }
            else {
                window.setTimeout(async () => {
                    // to 
                    //"fullPath":"/login","hash":"","query":{},"name":"login","path":"/login","params":{},"matched":[{"path":"/login","name":"login","meta":{},"props":{"default":false},"children":[],"instances":{},"leaveGuards":{},"updateGuards":{},"enterCallbacks":{},"components":{}}],"meta":{},"href":"#/login"}
                    //
                    // user tried to access this page but was not logged in, so save it so they may return to it once logged in
                    if (!STN.isBlank(to.name) && to.name != "login") {
                        store.commit('setBackgroundMessagePayloadParams', to.name);
                    }

                    // user not logged in yet, see if we have saved session info  
                    let l_oStoredCredentials = JSON.parse(localStorage.getItem("RememberMe"));

                    if (!STN.isBlank(l_oStoredCredentials)) {
                        // login with vuex
                        await store.dispatch("auth/login", l_oStoredCredentials).then(
                            async (result) => {
                                if (await STN.isVendorLogin() == true) {
                                    if (await FilterVendorLoginAccess(to) == true) {
                                        // route allowed, proceed
                                        STN.actionMessageClear();
                                        next();
                                    } else {
                                        // route not allowed, deny / redirect
                                        STN.redirect({
                                            name: "myshops_scheduled"
                                        });
                                    }
                                } else if (await STN.isBestmarkNevadaMultishopperLogin() == true) {
                                    if (!STN.isBlank(result) && !STN.isBlank(result.AssignmentID)) {
                                        // first site hit from link, redirect
                                        STN.redirect({
                                            name: "findshops_preview",
                                            params: {
                                                assignmentID: result.AssignmentID,
                                                lastViewedShop: result.AssignmentID,
                                                callingPage: "dashboard",
                                                pageName: "findshops_preview",
                                            },
                                        });
                                    } else {
                                        if (await FilterBestmarkNevadaMultishopperLoginAccess(to) == true) {
                                            // route allowed, proceed
                                            STN.actionMessageClear();
                                            next();
                                        } else {
                                            // route not allowed, deny / redirect
                                            let error = "Please try accessing the site again from your email link.";
                                            STN.logError(
                                                "router.js",
                                                "PreRouteChecks - BestmarkNevadaMultishopperLogin Link Error1:  " + error
                                            );
                                        }
                                    }
                                } else {
                                    if (!STN.isBlank(to.name) && to.name != "login") {
                                        let l_oRouteData = (!STN.isBlank(localStorage.getItem('routeData')) ? JSON.parse(localStorage.getItem('routeData')) : {});

                                        STN.redirect({
                                            name: to.name,
                                            params: l_oRouteData
                                        });
                                    } else {
                                        STN.redirect({
                                            name: "dashboard"
                                        });
                                    } 
                                }
                            },
                            (error) => {
                                STN.logError(
                                    "router.js",
                                    "PreRouteChecks - Error2:  " + error
                                );
                                router.replace("/login");
                            }
                        );
                    }
                    else {
                        // remember me not set and not logged in, continue...
                        if (to.path != "/login" && to.path != "login") {
                            // prevent loop of death
                            router.replace("/login");
                        }
                        else {
                            STN.actionMessageClear();
                            next();
                            STN.hideProgress();
                            window.scroll(0, 0);

                            // if (STN.isVendorLogin()) {

                            // } else {
                            //     STN.actionMessageClear();
                            //     next();
                            //     STN.hideProgress();
                            //     window.scroll(0, 0);
                            // }
                        }
                    }
                }, 1000);
            }
        }
    }, 200);
};


const FilterVendorLoginAccess = async function (to) {
    if (!STN.isBlank(to) && !STN.isBlank(to.name) && to.name.indexOf("error") == -1
        && to.name.indexOf("findshops_certifications") == -1
        && to.name.indexOf("findshops_disclaimer") == -1
        && to.name.indexOf("findshops_preview") == -1
        && to.name.indexOf("gallery_audio") == -1
        && to.name.indexOf("gallery_clarification") == -1
        && to.name.indexOf("gallery_image") == -1
        && to.name.indexOf("gallery_video") == -1
        && to.name.indexOf("maintenance") == -1
        && to.name.indexOf("myshops_attention") == -1
        && to.name.indexOf("myshops_checkin") == -1
        && to.name.indexOf("myshops_clarification") == -1
        && to.name.indexOf("myshops_scheduled") == -1
        && to.name.indexOf("questionnaire") == -1  // covers all questionnaire pages
        && to.name.indexOf("reports_shopinvoice") == -1) {

        // trying to go to a route that is NOT one of the above, deny and redirect  
        return false;
    } else {
        // route allowed
        return true;
    }
};


const FilterBestmarkNevadaMultishopperLoginAccess = async function (to) {
    if (!STN.isBlank(to) && !STN.isBlank(to.name) && to.name.indexOf("error") == -1
        && to.name.indexOf("findshops_certifications") == -1
        && to.name.indexOf("findshops_disclaimer") == -1
        && to.name.indexOf("findshops_preview") == -1
        && to.name.indexOf("gallery_audio") == -1
        && to.name.indexOf("gallery_clarification") == -1
        && to.name.indexOf("gallery_image") == -1
        && to.name.indexOf("gallery_video") == -1
        && to.name.indexOf("maintenance") == -1
        && to.name.indexOf("myshops_checkin") == -1
        && to.name.indexOf("myshops_clarification") == -1
        && to.name.indexOf("questionnaire") == -1  // covers all questionnaire pages
        && to.name.indexOf("reports_shopinvoice") == -1) {

        // trying to go to a route that is NOT one of the above, deny and redirect  
        return false;
    } else {
        // route allowed
        return true;
    }
};


const routes = [
    /////////////////////////////// all other (new) routes
    {
        path: "/",
        component: () => import("@/components/login/Login.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },


    {
        name: "apply",
        path: "/apply",
        component: () => import("@/components/apply/Apply.vue"),
    },
    {
        name: "apply_continueapplication",
        path: "/apply_continueapplication",
        component: () => import("@/components/apply/Apply_ContinueApplication.vue"),
    },
    {
        name: "apply_finishapplication",
        path: "/apply_finishapplication",
        component: () => import("@/components/apply/Apply_FinishApplication.vue"),
    },
    {
        name: "apply_ica",
        path: "/apply_ica",
        component: () => import("@/components/apply/Apply_ICA.vue"),
    },


    {
        name: "dashboard",
        path: "/dashboard",
        component: () => import("@/components/dashboard/Dashboard.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "dashboard_announcement",
        path: "/dashboard_announcement",
        component: () => import("@/components/dashboard/Dashboard_Announcement.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },


    {
        name: "login",
        path: "/login",
        component: () => import("@/components/login/Login.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },


    {
        name: "error",
        path: "/error",
        component: () => import("@/components/error/Error.vue"),
    },
    {
        name: "error_other",
        path: "/error_other",
        component: () => import("@/components/error/Error_Other.vue"),
    },
    {
        name: "error_restricted",
        path: "/error_restricted",
        component: () => import("@/components/error/Error_Restricted.vue"),
    },


    {
        name: "findshops_certifications",
        path: "/findshops_certifications",
        component: () => import("@/components/findshops/FindShops_Certifications.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "findshops_disclaimer",
        path: "/findshops_disclaimer",
        component: () => import("@/components/findshops/FindShops_Disclaimer.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "findshops_feedback",
        path: "/findshops_feedback",
        component: () => import("@/components/findshops/FindShops_Feedback.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "findshops_preview",
        path: "/findshops_preview",
        component: () => import("@/components/findshops/FindShops_Preview.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "findshops_previewshop",
        path: "/findshops_previewshop",
        component: () => import("@/components/findshops/FindShops_PreviewShop.vue"),
    },
    {
        name: "findshops_results_list",
        path: "/findshops_results_list",
        component: () => import("@/components/findshops/FindShops_Results_List.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "findshops_results_listonly",
        path: "/findshops_results_listonly",
        component: () => import("@/components/findshops/FindShops_Results_ListOnly.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "findshops_results_map",
        path: "/findshops_results_map",
        component: () => import("@/components/findshops/FindShops_Results_Map.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "findshops_search_list",
        path: "/findshops_search_list",
        component: () => import("@/components/findshops/FindShops_Search_List.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "findshops_search_map",
        path: "/findshops_search_map",
        component: () => import("@/components/findshops/FindShops_Search_Map.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },


    {
        name: "gallery_audio",
        path: "/gallery_audio",
        component: () => import("@/components/gallery_audio/Gallery_Audio.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_audio_upload",
        path: "/gallery_audio_upload",
        component: () => import("@/components/gallery_audio/Gallery_Audio_Upload.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_audio_view",
        path: "/gallery_audio_view",
        component: () => import("@/components/gallery_audio/Gallery_Audio_View.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_clarification",
        path: "/gallery_clarification",
        component: () => import("@/components/gallery_clarification/Gallery_Clarification.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_clarification_edit",
        path: "/gallery_clarification_edit",
        component: () => import("@/components/gallery_clarification/Gallery_Clarification_Edit.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_clarification_upload",
        path: "/gallery_clarification_upload",
        component: () => import("@/components/gallery_clarification/Gallery_Clarification_Upload.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_clarification_view",
        path: "/gallery_clarification_view",
        component: () => import("@/components/gallery_clarification/Gallery_Clarification_View.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_ein",
        path: "/gallery_ein",
        component: () => import("@/components/gallery_ein/Gallery_EIN.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "gallery_ein_edit",
        path: "/gallery_ein_edit",
        component: () => import("@/components/gallery_ein/Gallery_EIN_Edit.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "gallery_ein_upload",
        path: "/gallery_ein_upload",
        component: () => import("@/components/gallery_ein/Gallery_EIN_Upload.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "gallery_image",
        path: "/gallery_image",
        component: () => import("@/components/gallery_image/Gallery_Image.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_image_edit",
        path: "/gallery_image_edit",
        component: () => import("@/components/gallery_image/Gallery_Image_Edit.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_image_upload",
        path: "/gallery_image_upload",
        component: () => import("@/components/gallery_image/Gallery_Image_Upload.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_image_view",
        path: "/gallery_image_view",
        component: () => import("@/components/gallery_image/Gallery_Image_View.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_photoid",
        path: "/gallery_photoid",
        component: () => import("@/components/gallery_photoid/Gallery_PhotoID.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_photoid_upload",
        path: "/gallery_photoid_upload",
        component: () => import("@/components/gallery_photoid/Gallery_PhotoID_Upload.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_profilephoto",
        path: "/gallery_profilephoto",
        component: () => import("@/components/gallery_profilephoto/Gallery_ProfilePhoto.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "gallery_profilephoto_edit",
        path: "/gallery_profilephoto_edit",
        component: () => import("@/components/gallery_profilephoto/Gallery_ProfilePhoto_Edit.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "gallery_profilephoto_upload",
        path: "/gallery_profilephoto_upload",
        component: () => import("@/components/gallery_profilephoto/Gallery_ProfilePhoto_Upload.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "gallery_video",
        path: "/gallery_video",
        component: () => import("@/components/gallery_video/Gallery_Video.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_video_upload",
        path: "/gallery_video_upload",
        component: () => import("@/components/gallery_video/Gallery_Video_Upload.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },
    {
        name: "gallery_video_view",
        path: "/gallery_video_view",
        component: () => import("@/components/gallery_video/Gallery_Video_View.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
        meta: {
            layout: "Layout_NoHeader",
        },
    },


    {
        name: "helpdesk",
        path: "/helpdesk",
        component: () => import("@/components/helpdesk/HelpDesk.vue"),
        // beforeEnter: (to, from, next) => {
        //     CheckRootOrJailbreak(next);
        // },
    },
    {
        name: "helpdesk_faq",
        path: "/helpdesk_faq",
        component: () => import("@/components/helpdesk/Help_FAQ.vue"),
        // beforeEnter: (to, from, next) => {
        //     PreRouteChecks(next);
        // },
    },


    {
        name: "messages",
        path: "/messages",
        component: () => import("@/components/messages/Messages.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },


    {
        name: "maintenance",
        path: "/maintenance",
        component: () => import("@/components/maintenance/Maintenance.vue"),
    },

    
    {
        name: "myaccount_changeemail",
        path: "/myaccount_changeemail",
        component: () => import("@/components/myaccount/MyAccount_ChangeEmail.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_changepassword",
        path: "/myaccount_changepassword",
        component: () => import("@/components/myaccount/MyAccount_ChangePassword.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_deactivate",
        path: "/myaccount_deactivate",
        component: () => import("@/components/myaccount/MyAccount_Deactivate.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_extended",
        path: "/myaccount_extended",
        component: () => import("@/components/myaccount/MyAccount_Extended.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_extended_required",
        path: "/myaccount_extended_required",
        component: () => import("@/components/myaccount/MyAccount_Extended_Required.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_extended_refresh",
        path: "/myaccount_extended_refresh",
        component: () => import("@/components/myaccount/MyAccount_Extended_Refresh.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_general",
        path: "/myaccount_general",
        component: () => import("@/components/myaccount/MyAccount_General.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_ica_sign",
        path: "/myaccount_ica_sign",
        component: () => import("@/components/myaccount/MyAccount_ICA_Sign.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_ica_view",
        path: "/myaccount_ica_view",
        component: () => import("@/components/myaccount/MyAccount_ICA_View.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_myranking",
        path: "/myaccount_myranking",
        component: () => import("@/components/myaccount/MyAccount_MyRanking.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_notificationsettings",
        path: "/myaccount_notificationsettings",
        component: () => import("@/components/myaccount/MyAccount_NotificationSettings.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_emailsettings",
        path: "/myaccount_emailsettings",
        component: () => import("@/components/myaccount/MyAccount_EmailSettings.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_payment",
        path: "/myaccount_payment",
        component: () => import("@/components/myaccount/MyAccount_Payment.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_payment_add",
        path: "/myaccount_payment_add",
        component: () => import("@/components/myaccount/MyAccount_Payment_Add.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_payment_edit_directdeposit",
        path: "/myaccount_payment_edit_directdeposit",
        component: () => import("@/components/myaccount/MyAccount_Payment_Edit_DirectDeposit.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_payment_edit_paypal",
        path: "/myaccount_payment_edit_paypal",
        component: () => import("@/components/myaccount/MyAccount_Payment_Edit_Paypal.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_payment_setdefault",
        path: "/myaccount_payment_setdefault",
        component: () => import("@/components/myaccount/MyAccount_Payment_SetDefault.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_photoid",
        path: "/myaccount_photoid",
        component: () => import("@/components/myaccount/MyAccount_PhotoID.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_requirenewemail",
        path: "/myaccount_requirenewemail",
        component: () => import("@/components/myaccount/MyAccount_RequireNewEmail.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_retrievepassword",
        path: "/myaccount_retrievepassword",
        component: () => import("@/components/myaccount/MyAccount_RetrievePassword.vue"),
    },
    {
        name: "myaccount_tax",
        path: "/myaccount_tax",
        component: () => import("@/components/myaccount/MyAccount_Tax.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myaccount_verifyemail",
        path: "/myaccount_verifyemail",
        component: () => import("@/components/myaccount/MyAccount_VerifyEmail.vue"),
        // beforeEnter: (to, from, next) => {
        //     PreRouteChecks(to, from, next);
        // },
    },


    {
        name: "myshops_attention",
        path: "/myshops_attention",
        component: () => import("@/components/myshops/MyShops_Attention.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myshops_changeshopdate",
        path: "/myshops_changeshopdate",
        component: () => import("@/components/myshops/MyShops_ChangeShopDate.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myshops_checkin",
        path: "/myshops_checkin",
        component: () => import("@/components/myshops/MyShops_CheckIn.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myshops_checkininfo",
        path: "/myshops_checkininfo",
        component: () => import("@/components/myshops/MyShops_CheckInInfo.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myshops_clarification",
        path: "/myshops_clarification",
        component: () => import("@/components/myshops/MyShops_Clarification.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myshops_inprocess",
        path: "/myshops_inprocess",
        component: () => import("@/components/myshops/MyShops_Inprocess.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myshops_paymentspending",
        path: "/myshops_paymentspending",
        component: () => import("@/components/myshops/MyShops_PaymentsPending.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myshops_rejected",
        path: "/myshops_rejected",
        component: () => import("@/components/myshops/MyShops_Rejected.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myshops_requested",
        path: "/myshops_requested",
        component: () => import("@/components/myshops/MyShops_Requested.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "myshops_scheduled",
        path: "/myshops_scheduled",
        component: () => import("@/components/myshops/MyShops_Scheduled.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },


    {
        name: "propertyquestion_reimbursement",
        path: "/propertyquestion_reimbursement",
        component: () => import("@/components/questionnaire/propertyquestions/PropertyQuestion_Reimbursement.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "propertyquestion_shoptimes",
        path: "/propertyquestion_shoptimes",
        component: () => import("@/components/questionnaire/propertyquestions/PropertyQuestion_ShopTimes.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "questionnaire",
        path: "/questionnaire",
        component: () => import("@/components/questionnaire/Questionnaire.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "questionnaire_header",
        path: "/questionnaire_header",
        component: () => import("@/components/questionnaire/Questionnaire_Header.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "questionnaire_shopinvoice",
        path: "/questionnaire_shopinvoice",
        component: () => import("@/components/questionnaire/Questionnaire_Shopinvoice.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    
    {
        name: "referral",
        path: "/referral",
        component: () => import("@/components/referral/Referral.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "referral_email",
        path: "/referral_email",
        component: () => import("@/components/referral/Referral_Email.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "referral_email_location",
        path: "/referral_email_location",
        component: () => import("@/components/referral/Referral_Email_Location.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "referral_search",
        path: "/referral_search",
        component: () => import("@/components/referral/Referral_Search.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "referral_status",
        path: "/referral_status",
        component: () => import("@/components/referral/Referral_Status.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    

    {
        name: "reports_1099",
        path: "/reports_1099",
        component: () => import("@/components/reports/Reports_1099.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "reports_paymenthistory",
        path: "/reports_paymenthistory",
        component: () => import("@/components/reports/Reports_PaymentHistory.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "reports_paymentspending",
        path: "/reports_paymentspending",
        component: () => import("@/components/reports/Reports_PaymentsPending.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
    {
        name: "reports_shopinvoice",
        path: "/reports_shopinvoice",
        component: () => import("@/components/reports/Reports_Shopinvoice.vue"),
        beforeEnter: (to, from, next) => {
            PreRouteChecks(to, from, next);
        },
    },
];


const router = createRouter({
    history: createWebHashHistory(),
    mode: 'hash',
    routes,
});


router.afterEach(() => {
    STN.hideProgress();
});


router.beforeEach(() => {
    STN.showProgress();
    // store.commit('setIsShopsNavOpen', false);
    // store.commit('setIsAccountNavOpen', false);
    // store.commit('setIsHelpNavOpen', false);
});


export default router;
