<template>
<footer>
    <div class="row" style="margin:auto;">
        <div class="foot_phone"></div>
        <div class="foot_subscribe"></div>
    </div>
    <div class="row logos" style="margin:auto;">
        <a href="https://www.second-to-none.com/"><img src="../../assets/images/logo_desktop.jpg" alt="CX Group" /></a>
        <a href="https://www.bestmark.com/"><img src="../../assets/images/Bestmark_Logo_Reverse-Sm.jpg" height="40" alt="Bestmark" /></a>
        <a href="https://www.mspa-global.org/" target="_blank"><img src="../../assets/images/logo_mspa_member.png" alt="MSPA Member" /></a>
        <a href="https://www.cxpa.org/" target="_blank"><img src="../../assets/images/logo_cxpa.png" alt="CSPA - Customer Experience Professionals Association" /></a>
    </div>
    <div class="row logos" style="padding-top:20px;margin:auto;">
        <img src="../../assets/images/powered_by_google_on_white.png" />
    </div>
    <div class="row" style="margin:auto;">
        <div class="copyright">
            &copy; 2024 CX Group. All rights reserved. <a href="https://www.cxgroup.com/privacy-policy">Privacy Policy</a>.
        </div>
    </div>
</footer>
</template>
